import axios from 'axios';

const client = axios.create({
  baseURL: 'https://winkel.obie.dev/api',
  headers: {
    authorization: process.env.API_KEY
  }
});

export default client;
