export const errorIcon = {
  size: 'sm',
  id: 'warning',
  title: 'An error occured',
  classNames: ['ml--xs']
};

export const successIcon = {
  size: 'sm',
  id: 'check',
  title: 'Action successful',
  classNames: ['ml--xs']
};

export const infoIcon = {
  size: 'sm',
  id: 'info',
  title: 'Inormation',
  classNames: ['ml--xs']
};

export const deleteIcon = {
  size: 'sm',
  id: 'trash',
  title: 'delete contact',
  classNames: ['vert--mid']
};

export const editIcon = {
  size: 'sm',
  id: 'pencil',
  title: 'edit contact',
  classNames: ['vert--mid']
};
